
// 跳到商详
export function jumpGoodsTo (detailId) {
  window.open(
    `/syoung/commodity/goods-detail/${detailId}`
  );
}
// 跳转商家端
export function jumpTo (url) {
  window.open(url);
}
// 跳转外部链接
export function jumpToOuter (url) {
  window.open(url);
}