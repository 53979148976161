<template>
  <div
    :style="[{ backgroundImage: 'url(' + backgroundUrl + ')' }, styles.barndWrap.barndWrap]"
    class="barnd-wrap"
  >
    <div class="barnd-wrap__content" :style="styles.barndWrap.content">
      <div
        class="barnd-wrap__text-box"
        :style="[textBoxStyle, textLayout === 'left' ? changeLeft() : styles.barndWrap.textBox]"
      >
        <div class="barnd-wrap__text-box__content" :style="styles.barndWrap.textBoxContent">
          <div class="barnd-wrap__text-box-logo">
            <img :src="logo" alt class="logo-img" :style="styles.barndWrap.logoImg" />
          </div>
          <h3
            :style="[textBoxTitleStyle, styles.barndWrap.textBoxTitle]"
            class="barnd-wrap__text-box-title"
          >
            {{ textTitle }}
          </h3>
          <div
            :style="[textBoxPreStyle, styles.barndWrap.textBoxPresentation]"
            class="barnd-wrap__text-box-presentation"
          >
            {{ textPre }}
          </div>
        </div>
      </div>
      <div class="barnd-wrap__commodity-box" :style="styles.barndWrap.commodityBox">
        <div class="barnd-wrap__link-haeder">
          <a :style="textBoxPreStyle" @click="jumpToLinkUrl()" class="barnd-wrap__link-a">
            查看更多产品
            <span class="iconfont icon-you-copy"></span>
          </a>
        </div>
        <div class="barnd-wrap__link-content">
          <div :key="item.id" class="barnd-wrap__commodity-box__item" v-for="item of commodityList">
            <img
              :src="item.pictureUrl"
              @click="jumpToLink(item)"
              alt
              class="barnd-wrap__item__img"
              :style="styles.barndWrap.itemImg"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 宽高比率 1 : 0.417
/**
 * 品牌展示组件，
 * @param {类型} 参数 单独类型的参数
 * @param {[类型|类型|类型]} 参数 多种类型的参数
 * @param {类型} [可选参数] 参数 可选参数用[]包起来
 * @return {类型} 说明
 */
// import SvgIcon from '@/components/svg-icon';
import { jumpGoodsTo, jumpTo } from '@/utils/jump';
import eventReporter from '@/utils/event-reporter';
const clientWidth = document.body.clientWidth;
export default {
  name: 'BrandSubassembly',
  components: {},
  props: {
    // 页面来源
    event_source: {
      type: String,
      default: ''
    },
    logo: {
      type: String,
      default: ''
    },
    commodityList: {
      type: Array,
      default() {
        return [];
      }
    },
    brandId: {
      type: String,
      default: ''
    },
    backgroundUrl: {
      type: String,
      default: ''
    },
    textTitle: {
      type: String,
      default: ''
    },
    textColor: {
      type: String,
      default: '#222'
    },
    textPre: {
      type: String,
      default: ''
    },
    textPreColor: {
      type: String,
      default: '#222'
    },
    textLayout: {
      type: String,
      default: 'right'
    }
  },
  data() {
    return {
      styles: {
        barndWrap: {
          barndWrap: {
            height: this.fontSizePx(800)
          },
          content: {
            width: this.fontSizePx(1380),
            height: this.fontSizePx(800)
          },
          textBox: {
            width: this.fontSizePx(431),
            height: this.fontSizePx(410),
            left: this.fontSizePx(841)
          },
          textBoxContent: {
            marginBottom: this.fontSizePx(30)
          },
          textBoxTitle: {
            fontSize: this.fontSizePx(21),
            marginBottom: this.fontSizePx(17)
          },
          textBoxPresentation: {
            fontSize: this.fontSizePx(14),
            lineHeight: this.fontSizePx(24)
          },
          logoImg: {
            width: this.fontSizePx(100)
          },
          commodityBox: {
            width: this.fontSizePx(1380)
          },
          itemImg: {
            height: this.fontSizePx(320)
          }
        }
      }
    };
  },
  computed: {
    textBoxStyle() {
      return {
        color: this.textColor
      };
    },
    textBoxTitleStyle() {
      return {
        color: this.textColor
      };
    },
    textBoxPreStyle() {
      return {
        color: this.textPreColor
      };
    }
  },
  methods: {
    changeLeft() {
      return {
        width: this.fontSizePx(431),
        height: this.fontSizePx(410),
        left: this.fontSizePx(91)
      };
    },
    jumpToLinkUrl() {
      jumpTo(`/syoung/commodity/list?brandId=${this.brandId}`);
    },
    jumpToLink(item) {
      eventReporter.trackClick(this.$route, {
        name: 'goods',
        aliasName: '商品',
        query: item.commodityId,
        event_source: this.event_source
      });
      jumpGoodsTo(item.commodityId);
    },
    fontSizePx(val) {
      return `${(clientWidth * val) / 1920}px`;
    }
  }
};
</script>

<style lang='scss'  scoped>
$linkColor: #e8e8e8;
.icon-you-copy {
  font-size: 20px;
  margin-left: 3px;
  vertical-align: bottom;
}
.barnd-wrap {
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  background-position: center 0;
  background-size: 100% 100%;
  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    align-self: flex-end;
  }
  &__text-box {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-content: center;
    &.text-left {
      left: 91px;
    }
  }
  &__text-box__content {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }
  &__text-box-logo {
    .logo-img {
      display: block;
    }
    margin-bottom: 18px;
  }
  &__text-box-title {
    font-weight: 400;
  }
  &__text-box-presentation {
    text-align: justify;
  }
  &__link-haeder {
    display: flex;
    justify-content: flex-end;
    padding: 15px 14px;
    color: #fff;
  }
  &__link-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 27px;
    padding-top: 7px;
  }
  &__commodity-box {
    align-self: flex-end;
  }

  &__commodity-box__item:first-of-type {
    margin-left: 0;
  }
  &__commodity-box__item:last-of-type {
    margin-right: 0;
  }
  &__commodity-box__item {
    flex: 1;
    margin: 0 5px;
  }
  &__item__img {
    width: 100%;
    cursor: pointer;
    // transition: all 0.1s;
  }
  &__link-a {
    position: relative;
    line-height: 24px;
    font-size: 14px;
    &::after {
      content: '';
      position: absolute;
      bottom: -7px;
      left: 0;
      width: 100%;
      height: 1px;
      background: #cfcfcf;
    }
  }
}
</style>
